import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import settings from "./settings.json";
import DisableDevtool from "disable-devtool";

// Função para verificar se o domínio é diferente de localhost
const isMySite = () => {
  if (settings.hostname === "") {
    return true; // Considera qualquer dispositivo como móvel se a configuração estiver vazia
  }
  if (window.location.hostname === settings.hostname) {
    return true;
  } else {
    return false;
  }
};

// Função para verificar o dispositivo
const isMobileDevice = () => {
  // Cria uma expressão regular a partir dos user agents no settings.json
  // Verifica se a configuração de user agents está vazia
  if (settings.useragents === "") {
    return true; // Considera qualquer dispositivo como móvel se a configuração estiver vazia
  }
  const userAgentRegex = new RegExp(settings.useragents, "i");
  return userAgentRegex.test(navigator.userAgent);
};

// Função para verificar a localização do IP (simulada aqui, você deve integrar um serviço real)
const getIpLocation = async () => {
  try {
    const response = await fetch(`https://ipapi.co/json/`);
    const data = await response.json();
    return data.country; // Retorna o código do país
  } catch (error) {
    console.error("Erro ao obter a localização do IP:", error);
    return null; // Retorna null em caso de erro
  }
};

function Cloaker() {
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica o domínio
    if (!isMySite()) {
      window.location.href = settings.redirect;
      return;
    }

    // Verifica o dispositivo
    if (!isMobileDevice()) {
      window.location.href = "about:blank";
      return;
    }

    // Verifica a localização do IP
    const checkLocation = async () => {
      if (settings.country[0] === "") {
        return true; 
      }
      const location = await getIpLocation();
      if (!settings.country.includes(location)) {
        window.location.href = "about:blank";
      }
    };

    checkLocation();

    if (settings.inspectCode)
      DisableDevtool({
        // Redireciona o usuário se as DevTools forem abertas
        ondevtoolopen: function () {
          window.location.href = settings.inspectRedirect;
        },
      });
  }, [navigate]);
}

export default Cloaker;
