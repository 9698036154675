import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Spinner, Center } from "@chakra-ui/react";
import Page from "./pages/Page";
import Search from "./pages/Search.js";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Transparency from "./pages/Transparency";
import Policy from "./pages/Policy";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cloaker from "./cloaker/main.js";
import CreateDonation from "./pages/CreateDonation.js";

function App() {
  Cloaker();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    // Simulação de carregamento (substitua isso com sua lógica real, se necessário)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Ajuste o tempo conforme necessário

    return () => clearTimeout(timer);
  }, []); // Sem dependências para carregar apenas na montagem

  if (loading) {
    return (
      <Center className="loading-container" h="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/error" element={<Home />} />
        <Route path="/transparency" element={<Transparency />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/create" element={<CreateDonation />} />
        <Route path="/Policy" element={<Policy />} />
        <Route path="/:page" element={<Page />} />
        <Route path="/search/:params" element={<Search />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
