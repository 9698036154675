import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";

function CreateDonation() {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");

  const handleSubmit = () => {
    if (!email || !phone || !subject) {
      toast({
        title: "Erro",
        description: "Por favor, preencha todos os campos.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    toast({
      title: "Formulário enviado com sucesso!",
      description: "Entraremos em contato em breve.",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
    });

    // Limpar os campos após o envio (opcional)
    setEmail("");
    setPhone("");
    setSubject("");
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
      p={4}
    >
      <Heading as="h1" mb={6}>
        Criar Doação
      </Heading>
      <Text>
        Se você deseja criar uma doação, por favor, entre em contado preenchendo
        o formulário abaixo com o máximo de informações possíveis. Vamos
        analisar a sua situação para aprovar a sua doação.
      </Text>
      <Text mt={5} fontWeight={"bold"}>
        Infelizmente, esse é um procedimento necessário para evitar fraudes!
      </Text>

      <Box width="100%" maxW="md" p={6} borderRadius="md" boxShadow="lg" mt={5}>
        <FormControl id="email" mb={4} isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        <FormControl id="phone" mb={4} isRequired>
          <FormLabel>Telefone</FormLabel>
          <Input
            type="tel"
            placeholder="Seu telefone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormControl>

        <FormControl id="subject" mb={6} isRequired>
          <FormLabel>Fale sobre sua doação</FormLabel>
          <Textarea
            placeholder="Seja breve."
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </FormControl>

        <Button colorScheme="blue" width="full" onClick={handleSubmit}>
          Enviar
        </Button>
      </Box>
    </Flex>
  );
}

export default CreateDonation;
