import React from "react";
import { Box, Image, IconButton, useDisclosure } from "@chakra-ui/react";
import { FaHeart } from 'react-icons/fa';

function Favorite() {
  const { isOpen, onToggle } = useDisclosure(); // Controla o estado do ícone de favoritar

  return (
    <IconButton
      icon={<FaHeart />}
      isRound={true}
      aria-label="Favorite"
      position="absolute"
      top="10px"
      right="10px"
      color={isOpen ? "red.500" : "gray.500"}
      bg={"white"} // Cor de fundo
      onClick={onToggle} // Alterna entre favoritado e não favoritado
      fontSize='20px'
      variant=""
      zIndex={1000}
    />
  );
}

export default Favorite;
