import {
  Image,
  Text,
  Flex,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  HStack,
  Heading,
  Button,
  Box,
  Progress,
} from "@chakra-ui/react";

import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import data from ".././api/paginas.json"; // Importe o arquivo JSON
import dataOthers from ".././api/others.json"; // Importe o arquivo JSON

function Home() {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const ProgressBar = ({ value, colorScheme, size, label }) => {
    return (
      <Box>
        {label && <Text mb={2}>{label}</Text>}
        <Progress
          value={value}
          colorScheme={colorScheme}
          size={size}
          hasStripe
          isAnimated
        />
      </Box>
    );
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
    >
      <Box pos="relative" w={"100vw"} mt={-4}>
        <Swiper
          spaceBetween={8}
          slidesPerView={1}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          navigation
          modules={[Navigation, Pagination, A11y]}
        >
          {dataOthers.banners.map((image, index) => (
            <SwiperSlide key={index}>
              <Image
                src={`/assets/images/banners/${image.name}`}
                alt={`Image ${index + 1}`}
                objectFit="cover"
                w="100%"
                h="100%"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Heading mt={10}>Faça a sua parte!</Heading>
      <Box pos="relative" w={"100%"} maxW={"100%"} mt={5}>
        <Swiper
          spaceBetween={8}
          slidesPerView={2}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          modules={[Pagination, A11y]}
        >
          {data.map((page, index) => (
            <SwiperSlide key={index}>
              <Card
                borderTop="8px"
                borderColor="blue.500"
                width="100%" // Garante que o Card ocupe 100% da largura do SwiperSlide
              >
                <CardHeader p={0}>
                  <Image
                    src={`/assets/images/${page.images[0].name}`}
                    alt={`Image ${index + 1}`}
                    objectFit="cover"
                    width="100%" // Garante que a imagem ocupe 100% da largura do Card
                    height="auto" // Mantém a proporção da imagem
                    borderBottomRadius={8} // Opcional: Aplica um radius na parte inferior da imagem
                  />
                </CardHeader>
                <CardBody>
                  <Heading size="sm" mb={2} noOfLines={2}>
                    {page.subtitle}
                  </Heading>

                  <HStack justify="space-between" width="100%" mb={2}>
                    <Heading fontSize="10px">Arrecadado</Heading>
                    <Heading fontSize="8px">
                      {((page.AmountNow / page.AmountTarget) * 100)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}{" "}
                      %
                    </Heading>
                  </HStack>
                  <ProgressBar
                    value={(page.AmountNow / page.AmountTarget) * 100}
                    colorScheme="blue"
                    size="sm"
                  />
                  <HStack justify="space-between" width="100%" mt={2}>
                    <Heading color="blue.500" fontSize="10px">
                      {formatCurrency(page.AmountNow)}
                    </Heading>
                    <Heading fontSize="10px">
                      de {formatCurrency(page.AmountTarget)}
                    </Heading>
                  </HStack>
                </CardBody>
                <CardFooter
                  mt={-8}
                  display="flex"
                  flexDirection={"column"}
                  textAlign={"center"}
                  justifyContent="center"
                >
                  <Text mb={2} color={"red"} fontSize={12}>
                    Encerra em {page.DaysLeft} dias
                  </Text>
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    size="md"
                    as="a"
                    href={`/${page.endpoint}`}
                    w="100%"
                  >
                    Ver mais
                  </Button>
                </CardFooter>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Flex>
  );
}

export default Home;
