import { Flex, Box, Heading, Text } from "@chakra-ui/react";

function Policy() {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection={"column"}
      textAlign={"center"}
      p={4}
    >
      <Box id="privacy" mb={10}>
        <Heading as="h2" size="lg" mb={4}>
          Política de Privacidade
        </Heading>
        <Text>
          Sua privacidade é importante para nós. Esta política descreve como
          coletamos, usamos e protegemos suas informações pessoais quando você
          utiliza nosso site. Coletamos dados pessoais, como nome, e-mail e
          outras informações fornecidas por você, com o objetivo de melhorar sua
          experiência de usuário. Seus dados são armazenados de forma segura e
          utilizados exclusivamente para os fins descritos nesta política. Não
          compartilhamos suas informações com terceiros sem o seu consentimento,
          exceto quando exigido por lei.
        </Text>
      </Box>

      <Box id="terms" mb={10}>
        <Heading as="h2" size="lg" mb={4}>
          Termos de Uso
        </Heading>
        <Text>
          Ao acessar e utilizar nosso site, você concorda com os seguintes
          termos de uso. Você se compromete a usar o site de forma legal e
          ética, respeitando os direitos de outros usuários e de terceiros. Não
          é permitido o uso do site para atividades ilícitas, disseminação de
          conteúdo prejudicial ou violação de direitos autorais. Reservamo-nos o
          direito de modificar ou descontinuar o site a qualquer momento, sem
          aviso prévio. O uso continuado do site após alterações nos termos será
          considerado como aceitação das mudanças.
        </Text>
      </Box>

      <Box id="cookies" mb={10}>
        <Heading as="h2" size="lg" mb={4}>
          Política de Cookies
        </Heading>
        <Text>
          Este site utiliza cookies para melhorar sua experiência de navegação.
          Cookies são pequenos arquivos de texto armazenados no seu dispositivo
          que nos ajudam a lembrar de suas preferências e entender como você
          interage com nosso conteúdo. Os cookies podem ser usados para
          personalizar anúncios e analisar o tráfego do site. Você pode
          gerenciar suas preferências de cookies a qualquer momento através das
          configurações do seu navegador. Ao continuar a usar nosso site, você
          concorda com o uso de cookies conforme descrito nesta política.
        </Text>
      </Box>
    </Flex>
  );
}

export default Policy;
