import { FaSearch, FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import { FaHome, FaHandsHelping, FaAddressCard, FaEye } from "react-icons/fa";
import {
  List,
  ListItem,
  ListIcon,
  useOutsideClick,
  HStack,
  Icon,
  IconButton,
  Flex,
  Image,
  Input,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import dataOthers from ".././api/others.json"; // Importe o arquivo JSON

function Header() {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const menuRef = useRef(null);

  useOutsideClick({
    ref: menuRef,
    handler: () => setIsMenuActive(false),
  });

  const handleMenuToggle = () => {
    setIsMenuActive((prev) => !prev);
    setIsAnimating(true);
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/search/${searchTerm}`);
    }
  };

  return (
    <Flex
      h={{ base: "60px", md: "60px", lg: "80px" }}
      bg="blue.500"
      alignItems="center"
      justifyContent="space-between"
      px={4}
    >
      {/* Botão de menu */}
      <IconButton
        aria-label="Menu"
        icon={
          <Icon as={FaBars} color={isMenuActive ? "#f9f9fa" : "gray.200"} />
        }
        variant="solid"
        bg="transparent"
        _hover={{ bg: "transparent" }}
        size="lg"
        onClick={handleMenuToggle}
      />

      {/* Menu */}
      <Box
        ref={menuRef}
        position="fixed"
        top={0}
        left={0}
        w={{ base: "70%", md: "70%", lg: "70%" }}
        h="100%"
        bg="#f9f9fa"
        zIndex={99999}
        transition="transform 0.4s ease-in-out, opacity 0.4s ease-in-out, visibility 0.4s ease-in-out"
        transform={isMenuActive ? "translateX(0)" : "translateX(-100%)"}
        opacity={isMenuActive ? 1 : 0}
        visibility={isMenuActive ? "visible" : "hidden"}
        p={5}
        boxShadow="2px 0 5px rgba(0, 0, 0, 0.1)"
        borderRight={"1px solid #ececed"}
        onTransitionEnd={handleAnimationEnd}
      >
        {/* Botão para fechar o menu */}
        <IconButton
          aria-label="Close"
          icon={<Icon as={FaTimes} />}
          variant="solid"
          bg="transparent"
          _hover={{ bg: "transparent" }}
          size="lg"
          onClick={handleMenuToggle}
        />

        {/* Itens do menu */}
        <List fontSize={18} mt={5}>
          <Link
            to="/"
            _hover={{ textDecoration: "none" }}
            onClick={handleMenuToggle}
          >
            <ListItem
              py={2}
              px={3}
              borderRadius="md"
              _hover={{ bg: "gray.200", color: "blue.600", cursor: "pointer" }}
              _active={{ bg: "#3182ce", color: "white" }}
              color="gray.800"
            >
              <ListIcon
                as={FaHome}
                mr={3}
                _hover={{ color: "blue.600" }}
                _active={{ color: "white" }}
              />
              Doar
            </ListItem>
          </Link>
          <Link
            to="/contact"
            _hover={{ textDecoration: "none" }}
            onClick={handleMenuToggle}
          >
            <ListItem
              py={2}
              px={3}
              borderRadius="md"
              _hover={{ bg: "gray.200", color: "blue.600", cursor: "pointer" }}
              _active={{ bg: "#3182ce", color: "white" }}
              color="gray.800"
            >
              <ListIcon
                as={FaAddressCard}
                mr={3}
                _hover={{ color: "blue.600" }}
                _active={{ color: "white" }}
              />
              Contato
            </ListItem>
          </Link>
          <Link
            to="/create"
            _hover={{ textDecoration: "none" }}
            onClick={handleMenuToggle}
          >
            <ListItem
              py={2}
              px={3}
              borderRadius="md"
              _hover={{ bg: "gray.200", color: "blue.600", cursor: "pointer" }}
              _active={{ bg: "#3182ce", color: "white" }}
              color="gray.800"
            >
              <ListIcon
                as={FaHandsHelping}
                mr={3}
                _hover={{ color: "blue.600" }}
                _active={{ color: "white" }}
              />
              Criar Doação
            </ListItem>
          </Link>
          <Link
            to="/transparency"
            _hover={{ textDecoration: "none" }}
            onClick={handleMenuToggle}
          >
            <ListItem
              py={2}
              px={3}
              borderRadius="md"
              _hover={{ bg: "gray.200", color: "blue.600", cursor: "pointer" }}
              _active={{ bg: "#3182ce", color: "white" }}
              color="gray.800"
            >
              <ListIcon
                as={FaEye}
                mr={3}
                _hover={{ color: "blue.600" }}
                _active={{ color: "white" }}
              />
              Transparência
            </ListItem>
          </Link>
        </List>
      </Box>

      {/* Logo */}
      <HStack spacing={4} justify="center">
        {!isSearchActive && (
          <Link to="/">
            <Image
              h={{ base: "70px", md: "70px", lg: "80px" }}
              src={`/assets/images/${dataOthers.logo}`}
            />
          </Link>
        )}
      </HStack>

      {/* Barra de pesquisa e ícone */}
      <HStack spacing={0} justify="right">
        {isSearchActive && (
          <Input
            placeholder="Pesquisar..."
            w="100%"
            mt={2}
            mb={2}
            px={4}
            py={2}
            color={"#27272a"}
            bg={"#f9f9fa"}
            borderRadius="md"
            border="1px solid"
            borderColor="gray.200"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        <IconButton
          aria-label="Search"
          icon={
            <Icon
              as={FaSearch}
              color={isSearchActive ? "#f9f9fa" : "gray.200"}
            />
          }
          variant="solid"
          bg="transparent"
          _hover={{ bg: "transparent" }}
          size="lg"
          onClick={() => {
            setIsSearchActive(!isSearchActive);
            handleSearch();
          }}
        />
      </HStack>
    </Flex>
  );
}

export default Header;
